@import '../../styles/global.scss';

.chip {
    all: unset;
    cursor: pointer;
    padding: $spacing-4 $spacing-16 !important;
    border: 1px solid $divider;
    width: auto;
    border-radius: to-rem(4);
    @include body-regular;
  
    &:hover {
      border: 1px solid #006AFF;
      background: rgba(0, 106, 255, 0.05);
    }
  
    &--active {
      border: 1px solid #006AFF;
      background: rgba(0, 106, 255, 0.05);
  
      .chip__icon {
        color: #006AFF;
      }
    }
  
    &__icon {
      padding-right: $spacing-8;
    }
  }
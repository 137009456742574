@import '../../styles/global.scss';

.ach-example {
  display: flex;
  flex-direction: column;
  padding: $spacing-24;
  background-color: white;
  gap: $spacing-24;
  border: 1px solid $divider;
  @include border-radius;

  @include desktop{
    padding: $spacing-32;
  }

  &__title-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }

  &__title {
    color: var(--Neutral-Neutral-950, #1A1B23);
    font-family: "Helvetica Neue";
    font-size: to-rem(16);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  &__title-divider {
    background-color: #5C6DFF;
    width: to-rem(40);
    height: to-rem(2);
    border: none;
    margin: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
    align-items: flex-end;

    @include desktop{
        flex-direction: row;
    }
  }

  &__label {
    color: #303441;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
  }

  &__input-field {
    fill: white;
    border: 1px solid #D8DAE1;
    height: to-rem(28);
    align-self: stretch;
    border-radius: to-rem(4);
    padding: $spacing-4 $spacing-8;
    width: -webkit-fill-available;

    &:active,
    &:focus {
      border: 1px solid #5C6DFF;
      outline: none;
    }

    &.active {
      border: 1px solid #5C6DFF;
    }
  }

  &__input-container {
    width: 100%;
  }

  &__cheque-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $spacing-24 $spacing-16;
    background: #FFF;
    box-shadow: 0px 5px 20px 0px rgba(52, 68, 115, 0.15);
    border-radius: to-rem(4);
    border-bottom: 4px solid #5C6DFF;


    @include tablet{
        padding: $spacing-32 $spacing-16;
    }
  }

  &__cheque-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    align-items: center;
    cursor: pointer;

    &.active {
      .ach-example__cheque-box-number {
        font-weight: 700;
        color: #000;
      }
    }
  }

  &__cheque-inner-group {
    display: flex;
    flex-direction: row;
    gap: $spacing-8;
  }

  &__cheque-box-number {
    color: #979CAF;
    font-family: "Helvetica Neue";
    font-size: to-rem(18);  
    line-height: to-rem(24);  
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;

    @include desktop{
        font-size: to-rem(20);
        line-height: to-rem(26);
    }

    @include tablet{
        font-size: to-rem(20);
        line-height: to-rem(26);
    }
  }

  &__cheque-box-label {
    color: #5C6DFF;
    font-family: "Helvetica Neue";
    font-size: to-rem(12);
    font-style: normal;
    font-weight: 500;
    line-height: to-rem(12);
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }

  &__cheque-box-divider {
    height: to-rem(2);
    background-color: #A8B4FF;
    width: 100%;
    border: none;
  }

  &__inner-group-divider {
    display: flex;
    flex-direction: row;
    gap: $spacing-8;
    align-items: center;
    width: 100%;
  }

  &__cheque-symbol{
    min-width: to-rem(8);

    &--alone{
        margin-top: $spacing-4 ;
    }
  }
}
@import '../../styles/global.scss';

.input-field {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;

  &__label {
    @include label;
  }

  &__wrapper {
    position: relative;
    width: 100%;

    @include desktop {
      width: to-rem(200);
    }
  }

  &__input {
    height: to-rem(40);
    border: 1px solid $input-default;
    border-radius: to-rem(4);
    padding: 0 $spacing-8;
    @include body-small;
    box-sizing: border-box;
    width: 100%;
    padding-left: $spacing-8;

    &:focus {
      border: 2px solid $input-active;
      outline: none;
    }

    &--error {
      border-color: $input-error;
    }
  }

  &__toggle {
    position: absolute;
    right: $spacing-4;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: to-rem(16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $icon-bnt-default;

    &:hover{
        color: $icon-bnt-hover;
    }

    &:focus {
      outline: none;
    }
  }

  &__error {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-4;
    color: $input-error;
    padding-top: $spacing-4;

    &--text {
      @include body-small;
      font-weight: 500;
    }
  }

  &__showbnt{
    position: relative;
    right: to-rem(8);
  }
}
@import '../../styles/global.scss';

.action-card{
    display: flex;
    flex-direction: column;
    @include border-radius;
    overflow: hidden;
    border: 1px solid $divider-light;
    transition: transform 0.3s ease;
    background-color: $background-invert;
    text-decoration: none;
    color: $text;

    &:hover{
        border: none;
        transform: translateY(-10px);
        box-shadow: $regular-elevation;

        .action-card__image {
            transform: scale(1.25);
          }
    }

    &__content{
        padding: $spacing-24;
    }

    &__title{
        @include h4;
        padding-top: $spacing-8;

        @include desktop{
            padding-top: $spacing-12;
        }
    }
    
    &__image-wrapper{
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: auto;
        transition: transform 0.3s ease;
        object-fit: cover;
    }

    &__badge-group{
        display: flex;
        flex-direction: row;
        gap: $spacing-12;
    }
}
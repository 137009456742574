@import '../../styles/global.scss';

.full-width-image-section {
  background-color: $background-grey;

  &__section-wrapper {
    @include desktop {
      @include desktop-section-container;
      flex-direction: column;
    }

    @include tablet {
      @include tablet-section-container;
      flex-direction: column;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    padding: $spacing-32 $spacing-32 $spacing-16;
    gap: $spacing-8;

    @include desktop {
      padding: 0;
    }

    &--header,
    &--body {
      text-align: center;
    }
  }

  &__image-wrapper {
    display: flex;
    width: 100%;
    padding-bottom: $spacing-32;

    @include desktop {
      justify-content: center;
      margin: auto;
      padding-bottom: $spacing-0;
    }

    @include tablet {
      padding-bottom: $spacing-0;
    }

    &--image {
      width: 100%;

      @include desktop {
        width: 100%;
      }
    }
  }

  &.image-only {
    padding: 0;
    background-color: transparent;

    .full-width-image-section__section-wrapper {
      padding: 0;
      margin: 0;
      min-width: 100%;
    }

    .full-width-image-section__image-wrapper {
      padding: 0;
      margin: 0;
      padding: $spacing-16 0;

      &--image {
        width: 100vw;
        max-width: 100%;
        display: block;
      }
    }
  }
}
@import '../../styles/global.scss';

.page-layout-main {
    display: flex;
    flex-direction: column;
    height: 100vh;  

    &__wrapper{
        flex: 1;
        padding: 2rem;  
        display: flex;
        flex-direction: column;
        gap: $spacing-32;
        background-color: $background-grey;

        @include desktop{
            gap: 0;
            padding: 0;
        }
    }

    &__chips{
      display: flex;
      flex-direction: row;
      gap: $spacing-16;
      justify-content: center;

      @include desktop{
        padding-top: $spacing-24;
      }
    }
}
  
  .footer {
    margin: unset;
  }
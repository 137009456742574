@import '../../styles/global.scss';

.project-panel-display {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column-reverse;
  gap: $spacing-16;

  @include desktop {
    flex-direction: row;
    flex: 1;
    gap: $spacing-48;
  }

  @include tablet {
    flex-direction: row;
    flex: 1;
  }

  &__section-wrapper{
    display: flex;
    padding: $spacing-24 $spacing-32;
    gap: $spacing-16;
    flex-direction: column-reverse;

    @include desktop{
        display: flex;
        justify-content: center;
        padding: $spacing-32 $spacing-120;
        gap: $spacing-48;
        max-width: $desktop-total-width;
        margin: auto;
        flex-direction: row;
    }

    @include tablet{
        display: flex;
        padding: $spacing-24 $spacing-80;
        gap: $spacing-32;
        flex-direction: row;
    }

    &--reverse {
      @include desktop {
        flex-direction: row-reverse;
        gap: $spacing-48;
      }
  
      @include tablet {
        flex-direction: row-reverse;
        gap: $spacing-32;
      }
    }
}

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;

    @include desktop {
      max-width: 50%;
      justify-content: center;
    }

    @include tablet {
      max-width: 50%;
      justify-content: center;
    }

    &--header {
      @include h3;
      text-align: left;
      background-color: transparent;
      border: none;
    }

    &--body {
      text-align: left;
    }
  }

  &__image {
    width: 100%;
    border-radius: to-rem(8);
    margin: auto;

    @include desktop {
      border-radius: to-rem(16);
      max-width: 50%;
    }

    @include tablet {
      border-radius: to-rem(14);
      max-width: 50%;
    }
  }
  
  &__component{
    width: 100%;
    border-radius: to-rem(8);
    margin: auto;

    @include desktop {
      border-radius: to-rem(16);
      max-width: 50%;
    }

    @include tablet {
      border-radius: to-rem(14);
      max-width: 50%;
    }
  }

    &__image-pair {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
    width: 100%;

    @include tablet {
      flex-direction: row;
      gap: $spacing-24;
    }

    @include desktop {
      flex-direction: row;
      gap: $spacing-24;
    }

    &__image {
      @include border-radius;
      width: 100%;
    }
  }

  &__video {
    width: 100%;
    border-radius: to-rem(8);
    margin: auto;
    object-fit: cover;

    @include desktop {
      border-radius: to-rem(16);
      max-width: 50%;
    }

    @include tablet {
      border-radius: to-rem(14);
      max-width: 50%;
    }
  }
  
}


@import '../../styles/global.scss';

.footer {
    background-color: $background-grey;
    padding: $spacing-32 0;
    display: flex;
    justify-content: center;
    align-items: center;  
    text-align: center;
    margin: auto;
    border-top: 1px solid $divider;
  
    &--true {
      background-color: transparent;
    }
  
    @include desktop {
      min-height: to-rem(101);
      padding: 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }
  
    &__text {
      @include body-small;
      color: $text-subtle;
      text-align: center;  
      line-height: 1.6;
    }
  }
@import '../../styles/global.scss';

.password-gate{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-16;

    @include desktop{
        padding: $spacing-48 0;
    }

    &__image{
       width: to-rem(320); 
    }

    &__heading{
        @include h5;
    }

    &__form{
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        width: 100%;

        @include desktop{
            width: auto;
        }

        @include tablet{
            width: auto;
        }
    }
}
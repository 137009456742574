@import '../../styles/global.scss';


.page-layout{
    background-color: $background-grey;

    &__main-figma{
        @include project-section;
        margin: auto;
        align-items: center;
        justify-content: center;
        @include mobile-section-container;
        
        @include desktop{
            @include desktop-section-container;
            height: 100vh;
        }
    
        @include tablet{
            @include tablet-section-container;
            flex-direction: column;
        }
    }

    &__image{
        width: 200px;
    }

    &__text{
        padding-bottom: $spacing-24;
    }

    &__header{
       
        @include desktop{
            text-align: center;
        }

        @include tablet{
            text-align: center;
        }
    }

    &__header-container{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            align-items: center;  
        }

        @include tablet{
            align-items: center;  
        }
    }

    &__figma{
        width: 100%;
        height: to-rem(500);
      
        @include tablet {
          width: to-rem(570);
          height: to-rem(647);
        }
      
        @include desktop {
            width: to-rem(570);
            height: to-rem(647);
        }
      
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: block;
        margin: 0 auto;
    }

    &__figma-label{
        @include label;
        font-style: italic;
    }
}
@import '../../styles/global.scss';

@keyframes sparkle {
    0%, 92%, 100% {
      transform: scale(1) rotate(0deg);
    }
  
    10% {
      transform: scale(1.1) rotate(10deg);
    }
  
    20% {
      transform: scale(0.95) rotate(-10deg);
    }
  
    30% {
      transform: scale(1.05) rotate(5deg);
    }
  
    40% {
      transform: scale(1) rotate(-5deg);
    }
  }

.title-content-block {
  @include desktop {
    padding: $spacing-48 $spacing-48 0 $spacing-48;
  }

  &__title {
    text-align: center;
  }

  &__title-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: $spacing-16;
    padding-bottom: $spacing-8;
  }

  &__highlight {
    position: relative;
  }

  &__stroke {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__sparkle {
    font-size: to-rem(16);
    animation: sparkle 6s infinite ease-in-out;

    @include desktop {
      font-size: to-rem(24);
    }

    @include tablet {
      font-size: to-rem(24);
    }
  }

  &__body {
    text-align: center;
  }

  &__heading-with-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; // ensures responsiveness
    gap: $spacing-12;
    text-align: center;

    @include tablet {
      flex-wrap: nowrap;
    }

    h1 {
      margin: 0;
    }
  }

  &__option {
    font-size: to-rem(14);
    margin-top: to-rem(8);

    @include desktop {
      margin-top: 0;
    }
  }
}
@import '../../styles/global.scss';

.badge{
    display: flex;
    flex-direction: row;
    gap: $spacing-8;
    align-items: flex-start;
    padding: $spacing-8;
    background-color: $background-mid-grey;
    border-radius: to-rem(4);
    width: fit-content;
    min-height: to-rem(32);
    box-sizing: border-box;

    &__icon{
        width: to-rem(16);
    }

    &__label{
        @include label;
        font-weight: 700;
        color: $text-subtle;
    }
}
@import '../../styles/global.scss';

.faq{
    background-color: $background-grey;

    &__section-wrapper{
        display: flex;
        padding: $spacing-32;
        flex-direction: column;

        @include desktop{
            @include desktop-section-container-tide-grid;
            gap: 0;
        }

        @include tablet{
            @include tablet-section-container;
        }
    }

    &__text-wrapper{
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        text-align: center;

        &--header{
            text-align: center;
        }
    
        &--body{
            text-align: center;
        }
    }
}
@import './variable';

@mixin desktop{
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin tablet{
    @media screen and (min-width: $mobile-breakpoint) and (max-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin h1{
    font-family: Lufga;
    font-size: $font-32;
    line-height: 2.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-48;
        line-height: 4rem;
    }

}

@mixin h2{
    font-family: Lufga;
    font-size: $font-24;
    line-height: 2rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-40;
        line-height: 3.5rem;
    }
}

@mixin h3{
    font-family: Lufga;
    font-size: $font-20;
    line-height: 2rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-32;
        line-height: 2.5rem;
    }
}

@mixin h4{
    font-family: Lufga;
    font-size: $font-18;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-24;
        line-height: 2rem;
    }
}

@mixin h5{
    font-family: Lufga;
    font-size: $font-16;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-24;
        line-height: 2rem;
    }
}

@mixin body-big{
    font-family: "inter", sans-serif;
    font-size: $font-18;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-18;
        line-height: 1.5rem;
    }
}

@mixin body-regular{
    font-family: "inter", sans-serif;
    font-size: $font-16;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-16;
        line-height: 1.75rem;
    }
}

@mixin body-small{
    font-family: "inter", sans-serif;
    font-size: $font-14;
    line-height: 1rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-14;
        line-height: 1.25rem;
    }
}

@mixin label{
    font-family: "inter", sans-serif;
    font-size: $font-12;
    line-height: 1rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-12;
        line-height: 1rem;
    }
}

@mixin body-hand-write{
    font-family: "Mansalva", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: $font-16;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;

    @include desktop{
        font-size: $font-16;
        line-height: 1.75rem;
    }
}


@mixin link-rm-style{
    text-decoration: none;
    opacity: 1;
}

//section controls
@mixin desktop-section-container{
    display: flex;
    justify-content: center;
    padding: $spacing-48 $spacing-120;
    gap: $spacing-32;
    max-width: $desktop-total-width;
    margin: auto;
}

@mixin desktop-section-container-tide-grid{
    display: flex;
    justify-content: center;
    padding: $spacing-48 $spacing-160;
    gap: $spacing-32; 
    max-width: $desktop-total-width;
    margin: auto;
}

@mixin desktop-section-container-no-bottom-spacing{
    display: flex;
    justify-content: center;
    padding: $spacing-48 $spacing-48 0 $spacing-48;
    gap: $spacing-32;
    max-width: $desktop-total-width;
    margin: auto;
}


@mixin mobile-section-container{
    display: flex;
    padding: $spacing-32;
    gap: $spacing-16;
    flex-direction: column;
}

@mixin mobile-section-container-no-bottom-spacing{
    display: flex;
    padding: $spacing-24 $spacing-24 0 $spacing-24;
    gap: $spacing-16;
    flex-direction: column;
}

@mixin tablet-section-container{
    display: flex;
    padding: $spacing-32 $spacing-80;
    gap: $spacing-32;
}

@mixin tablet-section-container-no-bottom-spacing{
    display: flex;
    padding: $spacing-32 $spacing-80 0 $spacing-80;
    gap: $spacing-32;
}

@mixin desktop-text-wrapper{
    padding: 0 $spacing-48;
}

//border radius
@mixin border-radius{
    border-radius: $mobile-radius;

    @include desktop{
        border-radius: $desktop-radius;
    }

    @include tablet{
        border-radius: $tablet-radius;
    }
}

@mixin border-radius-inner{
    border-radius: $mobile-radius-inner;

    @include desktop{
        border-radius: $desktop-radius-inner;
    }

    @include tablet{
        border-radius: $tablet-radius-inner;
    }
}

@mixin project-section{
    padding-bottom: $spacing-64 !important;

    @include desktop{
        padding-bottom: $spacing-96 !important;
    }

    @include desktop{
        padding-bottom: $spacing-64 !important;
    }
}

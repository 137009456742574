@import '../../styles/global.scss';

.action-cards-section{
    
    &__section-container{
        display: flex;
        gap: $spacing-24;
        flex-direction: column;
        padding: 0;
        
        @include desktop{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: $spacing-48 $spacing-120;
            gap: $spacing-32;
            max-width: $desktop-total-width;
            margin: auto;
        }
    
        @include tablet{
            @include tablet-section-container;
            flex-direction: column;
        }
    }

    &__card{
        width: 100%;

        @include desktop {
            width: calc(50% - 1rem);
            box-sizing: border-box;
        }
    }
}
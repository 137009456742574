@import "../../styles/global.scss";

.project-hero{
    background-color: $background-grey;
    transition: background-color 0.4s ease;
  
    &.scrolled {
      background-color: white;

      .project-hero__info-card {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }

    &__section-wrapper{
        @include mobile-section-container;
    
        @include desktop{
            @include desktop-section-container;
            flex-direction: column;
            gap: $spacing-24 !important;
        }
    
        @include tablet{
            @include tablet-section-container;
            flex-direction: column;
        }
    }

    &__info-card{
        padding: $spacing-24 $spacing-24 $spacing-160 $spacing-24;
        @include border-radius;
        background-color: $background-invert;
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        transition: padding 0.4s ease;

        @include desktop{
            padding: $spacing-48 $spacing-48 $spacing-64 $spacing-48;
        }

        @include tablet{
            padding: $spacing-32 $spacing-32 $spacing-64 $spacing-32;
        }
    }

    &__label{
        @include body-regular;
        color: $text-subtle;
        font-weight: 600;
    }

    &__content-group{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
    }

    &__body{
        text-align: left;
    }

    &__detail-table{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        @include border-radius-inner;

        @include desktop{
            flex-direction: row;
            border: 1px solid $divider;
            padding: $spacing-16 $spacing-24;
            gap: 0;
        }

        @include tablet{
            flex-direction: row;
            border: 1px solid $divider;
            padding: $spacing-16 $spacing-24;
            gap: 0;
        }
    }

    &__table-item{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        width: 100%;
        border-bottom: 1px solid $divider;
        padding: $spacing-12 0;

        @include desktop{
            border: none;
            padding: 0;
        }

        @include tablet{
            border: none;
            padding: 0;
        }
    }

    &__table-header{
        @include body-regular;
        text-align: left;
        color: $text-subtle;
    }

    &__table-body{
        @include body-regular;
        text-align: left;
    }

    &__image{
        width: 100%;
        @include border-radius-inner;

        @include desktop{
            margin-bottom: $spacing-16;
        }

        @include tablet{
            margin-bottom: $spacing-16;
        }
    }
}